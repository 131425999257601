@font-face {
  font-family: "Poppins";
  src: local("Poppins Poppins-SemiBoldMedium"), local("Poppins-Medium"),
    url("Poppinspoppins-semiboldmedium.woff2") format("woff2"),
    url("Poppinspoppins-semiboldmedium.woff") format("woff"),
    url("Poppinspoppins-semiboldmedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Bold"), local("Poppins-Bold"),
    url("Poppinsbold.woff2") format("woff2"),
    url("Poppinsbold.woff") format("woff"),
    url("Poppinsbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
