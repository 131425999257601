body {
  margin: 0;
  font-family: Poppins;
  font-style: normal;
  background: #f3f5f9;
  line-height: 166.3%;
  color: #202020;
}

#root {
  width: 100%;
}
